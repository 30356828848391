import React, { Fragment, useState, useEffect, useCallback } from "react";
import Canvg, { presets } from "canvg";
const Tabla2 = ({ lecturas, parentCallback }) => {
  const [lectu, setlectu] = useState({});
  const [url, seturl] = useState("");

  const [flag_bot, setflag_bot] = useState(false);
  const enviartabla = useCallback(() => {
    if (url !== "") {
      parentCallback(url);
      setflag_bot(true);
    }
    // eslint-disable-next-line
  }, [url]);
  useEffect(() => {
    enviartabla();
  }, [url, enviartabla]);
  useEffect(() => {
    //console.log(lecturas);
    setlectu(...lecturas);
  }, [lecturas]);

  useEffect(() => {
    if (lectu.cp !== undefined) {
      obtenerTabla(lectu);
    }
  }, [lectu]);
  const obtenerTabla = async (tabla) => {
    const alto = 60;
    let renglones =
      "<svg width='400px' height='" +
      alto +
      "px' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
      '<rect x="0" y="0" width="400" height="60" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="400" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>  <rect x="0" y="0" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>  <rect x="0" y="30" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';

    renglones =
      renglones +
      '<text x="0" y="20" font-family="Verdana" font-size="16px" font-weight="bold" fill="black" text-anchor="middle"> <tspan x="110">Caudal Promedio (l)</tspan> <tspan x="310">' +
      `${Number(tabla.cp).toFixed(2)}` +
      "</tspan>  </text>";
    renglones =
      renglones +
      '<text x="0" y="50" font-family="Verdana" font-size="16px" font-weight="bold" fill="black" text-anchor="middle"> <tspan x="110">Volumen Total (m3)</tspan> <tspan x="310">' +
      `${Number(tabla.vt).toFixed(2)}` +
      "</tspan>  </text>";

    renglones = renglones + "</svg>";
    const width = 650;
    const height = alto;
    const svg = renglones;
    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svg, presets.offscreen());

    // Render only first frame, ignoring animations and mouse.
    await v.render();

    const blob = await canvas.convertToBlob();
    const pngUrl = URL.createObjectURL(blob);
    seturl(pngUrl);
  };
  return (
    <Fragment>
      {flag_bot && (
        <div className="row mb-1  text-right">
          <div className="col-12 ">
            <div className="btn-group" role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a
                  rel="noreferrer"
                  className="btn btn-link"
                  href={url}
                  download
                >
                  Descargar PNG
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <table className="table table-sm table-bordered">
        <tbody>
          <tr>
            <th className="bg-info" scope="row">
              Gasto Promedio (l/s)
            </th>
            <td>{Number(lectu.cp).toFixed(2)}</td>
          </tr>
          <tr>
            <th className="bg-info" scope="row">
              Volumen Total (m3)
            </th>
            <td>{Number(lectu.vt).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default Tabla2;
