import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";

import "../src/componentes/v2/css/leaflet.css";
import "../src/componentes/v2/css/bootstrap.min.css";
import "../src/componentes/v2/css/mmenu.css";
import "../src/componentes/v2/css/mburger.css";
import "../src/componentes/v2/css/leaflet.css";
import "../src/componentes/v2/css/style.css";
//import './App.css';
import Mapa from "./componentes/v2/mapa/Mapa";
import Login from "./componentes/v2/login/Login2";
//dashboard-1
import Dashboard from "./componentes/v2/dashboard/Dashboard";
import Lecturas from "./componentes/v2/dashboard/lecturas/Lecturas";
import Informacion from "./componentes/v2/dashboard/informacion/Informacion";
import Graficas from "./componentes/v2/dashboard/graficas/Graficas";
import Volumen from "./componentes/v2/dashboard/volumen/Volumen";

//admin
import ModSitios from "./componentes/v2/admin/modsitios/Sitios.js";
import ModEquipos from "./componentes/v2/admin/modequipos/Equipos.js";
import ModNorma from "./componentes/v2/admin/modNormaC/ConfNorC.js";

//reporte Gasto

import ModReporte from "./componentes/reportes/ReporteB1/ReporteB.js";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <ProtectedRoute path="/mapa" exact component={Mapa} />
        <ProtectedRoute path="/dashboard/1/:id" exact component={Dashboard} />
        <ProtectedRoute path="/lecturas/1/:id" exact component={Lecturas} />
        <ProtectedRoute
          path="/informacion/1/:id"
          exact
          component={Informacion}
        />
        <ProtectedRoute path="/graficas/1/:id" exact component={Graficas} />
        <ProtectedRoute path="/volumen/1/:id" exact component={Volumen} />

        <ProtectedRoute path="/admin/modsitios/" exact component={ModSitios} />
        <ProtectedRoute
          path="/admin/modequipos/"
          exact
          component={ModEquipos}
        />
        <ProtectedRoute path="/admin/modnormac/" exact component={ModNorma} />
        <ProtectedRoute path="/mod/modreporteg/" exact component={ModReporte} />
        <Route
          path="*"
          component={() => {
            "404 NOT FOUND";
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
