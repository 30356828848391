import React, { Component } from "react";
import Navigation from "../Navigation";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { PDFViewer } from "@react-pdf/renderer";
import Panel from "./images/fondosactun.jpg";
import PDFReporteB from "./RB_PDFNew";
import GL from "./GraficasRB/GraficaLinea2";
import GT from "./GraficasRB/GraficaTotal";
import Lista from "./ListasSitios/ListaSitiosZ1";
import { ToastsContainer, ToastsStore } from "react-toasts";

export class Lecturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spin1: false,
      spin2: false,
      botonack: false,
      crear: false,
      imgMapaF: false,
      imgMapaF2: false,
      imgGrafF: false,
      imgGrafF2: false,
      imgGrafFh1: false,
      imgGrafFh2: false,
      imgGrafFT: false,
      fecha1: "",
      fecha1l: false,
      fecha2: "",
      fecha2l: false,
      fecha1mod: "",
      fecha2mod: "",
      fecha1prev: "",
      fecha2prev: "",
      fechat1: "",
      fechat1l: false,
      fechat2: "",
      fechat2l: true,
      fechat1mod: "",
      fechat2mod: "",
      fechat1fmod: "",
      fechat2fmod: "",
      fechat1prev: "",
      fechat2prev: "",
      acumulador: 0,
      observaciones: "",
      observaciones2: "",
      observaciones3: "",
      observaciones4: "",
      datos: [],
      datos2: [],
      datos3: [],
      imgMapa: "",
      imgMapa2: "",
      imgGraf: "",
      tabla: [],
      tabla2: [],
      tabla3: [],
      tablaprev: [],
      tablaFlag: false,
      peticion: [],
      peticion2: [],
      peticion3: [],
      tablaFlag2: false,
      hacerPDF: false,
      titulorojo: "",
      titulorojo2: "",
      titulorojo3: "",
      titulorojo4: "",

      titulog1h3: "",
      titulog2h3: "",
      titulog3h3: "",

      titulomitad1h4: "",
      titulomitad2h4: "",

      titulorojoflag: false,
      graftipo: "-",
      graftipoflag: false,
      pedirmapa: false,
      pedirmapa2: false,
      pedirgraf: false,
      pedirgraf2: false,
      pedirgrafh1: false,
      pedirgrafh2: false,
      pedirgraft: false,
      pedirgrafbar: false,

      h1g1: [],
      h1g2: [],
      h1g3: [],
      h1g4: [],
      h1g5: [],
      h1g6: [],
      h1g7: [],
      h1g8: [],
      h1g9: [],
      h1g10: [],
      h1g11: [],

      h2g1: [],
      h2g2: [],
      h2g3: [],
      h2g4: [],
      h2g5: [],
      h2g6: [],
      h2g7: [],
      h2g8: [],
      h2g9: [],
      h2g10: [],
      h2g11: [],

      allgrafIMG1: [],
      allgrafIMG2: [],
      allgrafIMGh1: [],
      allgrafIMGh2: [],
      allgrafIMGt: [],
      allgrafIMGBar: [],
      flagtituloLine: false,
      centermap: { lat: 0, lng: 0 },
      zoom: 15,
      centermap2: { lat: 0, lng: 0 },
      zoom2: 15,
      tablanueva: [],
      tablanueva2: [],
      horacorte: "",
      horacortel: false,
      digitoshoja2: [],
      digitoshoja2p: [],
      drenajeypozos: [],
      creahoja4: false,
      grafhoja4: false,
      /*bandera hoja 2*/ creahoja2: false,
      listasitios: [],
      barra1IMG: [],
      barra1IMGF: false,
      barra2IMG: [],
      barra2IMGF: false,
      barra3IMG: [],
      barra3IMGF: false,
      barra4IMG: [],
      barra4IMGF: false,

      hoja12: false,
      hoja22: false,
      tituloreporte: ""
    };
  }

  async componentDidMount() {
    const res = await axios.get(
      "https://api.instrutronica-iot.com/api/la/lista/sitioscaudal/calica"
    );
    this.setState({ listasitios: res.data });
  }

  tituloreportec = (event) => {
    this.setState({ tituloreporte: event.target.value });
  };

  cargarlecturas = async () => {
    if (
      this.state.fecha1 !== this.state.fecha1prev ||
      this.state.fecha2 !== this.state.fecha2prev ||
      this.state.tablaFlag === true
    ) {
      this.setState(
        {
          grafhoja4: false,
          spin1: true,
          imgGrafF: false,
          imgMapaF: false,
          tablaFlag: false,
          crear: false
        },
        async () => {
          const dat = [
            {
              fecha1: this.state.fecha1,
              fecha2: this.state.fecha2,
              fechat1: this.state.fechat1,
              fechat2: this.state.fechat2,
              hora: this.state.horacorte,
              sitios1: this.state.peticion,
              sitios2: this.state.peticion2
            }
          ];
          //console.log(dat);
          const res = await axios.post(
            "https://api.instrutronica-iot.com/api/la/reporteb/hoja123",
            dat
          );

          const r = res.data;

          let dp = this.state.digitoshoja2;

          if (r.h3[0].Sitios1[0].ta.length > 0) {
            for (let i = 0; i < r.h3[0].Sitios1[0].ta.length; i++) {
              const f1 = r.h3[0].Sitios1[0].ta[i].fechalectura
                .split("T")[0]
                .split("-");
              let q = new Date(f1[0], f1[1] - 1, Number(f1[2]), 0, 0, 0)
                .toLocaleString("es-MX")
                .split(" ")[0];

              const busca = (e) => {
                return e.fecha === q;
              };
              const busca2 = (e) => {
                return e.fecha !== q;
              };
              let c = dp.filter(busca);
              let c2 = dp.filter(busca2);

              if (c.length > 0) {
                c[0].tnc = Number(r.h3[0].Sitios1[0].ta[i].caudal).toFixed(2);
                dp = [...c2, ...c];
                dp.sort(function (a, b) {
                  if (a.id > b.id) {
                    return 1;
                  }
                  if (a.id < b.id) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
              }
            }
          }

          if (r.h3[0].Sitios2.length > 0) {
            for (let i = 0; i < r.h3[0].Sitios2[0].ta.length; i++) {
              const f1 = r.h3[0].Sitios2[0].ta[i].fechalectura
                .split("T")[0]
                .split("-");
              let q = new Date(f1[0], f1[1] - 1, Number(f1[2]), 0, 0, 0)
                .toLocaleString("es-MX")
                .split(" ")[0];

              const busca = (e) => {
                return e.fecha === q;
              };
              const busca2 = (e) => {
                return e.fecha !== q;
              };
              let c = dp.filter(busca);
              let c2 = dp.filter(busca2);

              if (c.length > 0) {
                c[0].tsc = Number(r.h3[0].Sitios2[0].ta[i].caudal).toFixed(2);
                dp = [...c2, ...c];
                dp.sort(function (a, b) {
                  if (a.id > b.id) {
                    return 1;
                  }
                  if (a.id < b.id) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
              }
            }
          }
          this.setState({
            fecha1prev: this.state.fecha1,
            fecha2prev: this.state.fecha2,
            acumulador: this.state.acumulador + 1,
            grafhoja4: true,
            peticion: this.state.tabla,
            peticion2: this.state.tabla2,
            datos: [...r.h1],
            datos2: [...r.h2],
            datos3: [...r.h3],
            digitoshoja2: dp,
            crear: true,
            spin1: false
          });
        }
      );
    }
  };

  titulorojocambia = (event) => {
    this.setState({ titulorojo: event.target.value, titulorojoflag: true });
  };
  titulorojocambia2 = (event) => {
    this.setState({ titulorojo2: event.target.value });
  };
  titulorojocambia3 = (event) => {
    this.setState({ titulorojo3: event.target.value });
  };
  titulorojocambia4 = (event) => {
    this.setState({ titulorojo4: event.target.value });
  };
  //titulos graficas hoja 3
  titulonorte = (event) => {
    this.setState({ titulog1h3: event.target.value });
  };
  titulosur = (event) => {
    this.setState({ titulog2h3: event.target.value });
  };
  titulototal = (event) => {
    this.setState({ titulog3h3: event.target.value });
  };
  //titulos graficas hoja 4
  titulomitad1 = (event) => {
    this.setState({ titulomitad1h4: event.target.value });
  };
  titulomitad2 = (event) => {
    this.setState({ titulomitad2h4: event.target.value });
  };
  //Funciones de fechas y hora
  fecha1(event) {
    let fe = event.target.value.split("-");
    let fss = false;
    if (this.state.tabla.length > 0) {
      fss = true;
    }
    this.setState({
      fecha1: event.target.value,
      fecha1l: true,
      datos: [],
      datos2: [],
      datos3: [],
      crear: false,
      hacerPDF: false,
      imgMapaF: false,
      imgGrafF: false,
      pedirgraf: false,
      pedirmapa: false,
      tablaFlag: fss,
      peticion: this.state.tabla,
      peticion2: this.state.tabla2,
      peticion3: [],
      observaciones: "",
      observaciones2: "",
      observaciones3: "",
      observaciones4: "",
      fecha1mod: new Date(fe[0], fe[1] - 1, fe[2])
        .toLocaleString("es-MX")
        .split(" ")[0]
    });
  }
  fecha2(event) {
    let fe = event.target.value.split("-");
    let fss = false;
    if (this.state.tabla.length > 0) {
      fss = true;
    }
    this.setState(
      {
        fecha2: event.target.value,
        datos: [],
        datos2: [],
        datos3: [],
        fecha2l: true,
        crear: false,
        imgMapaF: false,
        hacerPDF: false,
        pedirgraf: false,
        pedirmapa: false,
        imgGrafF: false,
        tablaFlag: fss,
        peticion: this.state.tabla,
        peticion2: this.state.tabla2,
        peticion3: [],
        observaciones: "",
        observaciones2: "",
        observaciones3: "",
        observaciones4: "",
        fecha2mod: new Date(fe[0], fe[1] - 1, fe[2])
          .toLocaleString("es-MX")
          .split(" ")[0]
      },
      () => {
        /*
        console.log(
          `f1: ${this.state.fecha1l} - f2: ${this.state.fecha2l} - ft2: ${this.state.fechat2l} - hora: ${this.state.horacortel} - imgT: ${this.state.tablaFlag}`
        );
        */
      }
    );
  }

  horacorte(event) {
    if (event.target.value !== "Seleccione la hora de corte") {
      let fss = false;
      if (this.state.tabla.length > 0) {
        fss = true;
      }
      this.setState(
        {
          horacorte: event.target.value,
          horacortel: true,
          datos: [],
          datos2: [],
          datos3: [],
          crear: false,
          hacerPDF: false,
          imgMapaF: false,
          imgGrafF: false,
          pedirgraf: false,
          pedirmapa: false,
          tablaFlag: fss,
          peticion: this.state.tabla,
          peticion2: this.state.tabla2,
          peticion3: [],
          observaciones: "",
          observaciones2: "",
          observaciones3: "",
          observaciones4: ""
        },
        () => {
          /*
          console.log(
            `f1: ${this.state.fecha1l} - f2: ${this.state.fecha2l} - ft2: ${this.state.fechat2l} - hora: ${this.state.horacortel} - imgT: ${this.state.tablaFlag}`
          );*/
        }
      );
    }
  }

  fechat1(event) {
    let fe = event.target.value.split("-");
    let fss = false;
    if (this.state.tabla.length > 0) {
      fss = true;
    }
    this.setState(
      {
        fechat1: event.target.value,
        fechat1l: true,
        datos: [],
        datos2: [],
        datos3: [],
        crear: false,
        hacerPDF: false,
        imgMapaF: false,
        imgGrafF: false,
        pedirgraf: false,
        pedirmapa: false,
        tablaFlag: fss,
        peticion: this.state.tabla,
        peticion2: this.state.tabla2,
        peticion3: [],
        observaciones: "",
        observaciones2: "",
        observaciones3: "",
        observaciones4: "",
        fechat1mod: new Date(fe[0], fe[1] - 1, fe[2])
          .toLocaleString("es-MX")
          .split(" ")[0]
      },
      () => {
        /*
        console.log(
          `f1: ${this.state.fecha1l} - f2: ${this.state.fecha2l} - ft2: ${this.state.fechat2l} - hora: ${this.state.horacortel} - imgT: ${this.state.tablaFlag}`
        );*/
      }
    );
  }
  async fechat2(event) {
    let fe = event.target.value.split("-");
    //buscar fechat1
    if (this.state.fechat1 !== "") {
      let f1 = this.state.fechat1.split("-");

      //ver si es <= 7 días, si sí, seguir con elgoritmo, sino fijar ft2 en el septimo día despues de fechat1 y mostrar una alerta y seguir con el algoritmo
      const t1 = new Date(f1[0], f1[1] - 1, f1[2], 0, 0, 0); //fechat t1
      const t2 = new Date(fe[0], fe[1] - 1, fe[2], 0, 0, 0); //fechat t2

      const dias = Math.ceil((t2 - t1) / 86400000);

      if (dias <= 7 && dias > -1) {
        //crear arreglo de dias
        let arr = [];
        for (let i = 0; i <= dias; i++) {
          let q = new Date(f1[0], f1[1] - 1, Number(f1[2]) + Number(i), 0, 0, 0)
            .toLocaleString("es-MX")
            .split(" ")[0];
          let ig = {
            id: i,
            tnc: 0,
            tsc: 0,
            dpc: 0,
            dmc: 0,
            kpic: 0,
            tdsc: 0,
            tsac: 0,

            fecha: q
          };
          arr = [...arr, ig];
        }
        let arr2 = [];
        for (let i = dias + 1; i <= dias + dias + 1; i++) {
          let q = new Date(f1[0], f1[1] - 1, Number(f1[2]) + Number(i), 0, 0, 0)
            .toLocaleString("es-MX")
            .split(" ")[0];
          let ig = {
            id: i,
            tnc: 0,
            tsc: 0,
            dpc: 0,
            dmc: 0,
            kpic: 0,
            tdsc: 0,
            tsac: 0,
            fecha: q
          };
          arr2 = [...arr2, ig];
        }
        const ffuturo1 = new Date(
          f1[0],
          f1[1] - 1,
          Number(f1[2]) + Number(dias + 1),
          0,
          0,
          0
        )
          .toLocaleString("es-MX")
          .split(" ")[0];

        const ffuturo2 = new Date(
          f1[0],
          f1[1] - 1,
          Number(f1[2]) + Number(dias + dias + 1),
          0,
          0,
          0
        )
          .toLocaleString("es-MX")
          .split(" ")[0];

        let arr3 = [];
        for (let i = 0; i <= dias; i++) {
          let q = new Date(f1[0], f1[1] - 1, Number(f1[2]) + Number(i), 0, 0, 0)
            .toLocaleString("es-MX")
            .split(" ")[0];
          let ig = {
            id: i,
            caudal: 0,
            fecha: q
          };
          arr3 = [...arr3, ig];
        }
        // console.log(arr3);
        let fss = false;
        if (this.state.tabla.length > 0) {
          fss = true;
        }
        this.setState(
          {
            fechat2: event.target.value,
            datos: [],
            datos2: [],
            datos3: [],
            digitoshoja2: [...arr],
            digitoshoja2p: [...arr2],
            drenajeypozos: [...arr3],
            fechat2l: true,
            crear: false,
            imgMapaF: false,
            hacerPDF: false,
            pedirgraf: false,
            pedirmapa: false,
            imgGrafF: false,
            creahoja4: true,
            grafhoja4: true,
            peticion: this.state.tabla,
            peticion2: this.state.tabla2,
            peticion3: [],
            tablaFlag: fss,
            observaciones: "",
            observaciones2: "",
            observaciones3: "",
            observaciones4: "",
            fechat1fmod: ffuturo1,
            fechat2fmod: ffuturo2,
            fechat2mod: t2.toLocaleString("es-MX").split(" ")[0]
          },
          () => {
            /*
            console.log(
              `f1: ${this.state.fecha1l} - f2: ${this.state.fecha2l} - ft2: ${this.state.fechat2l} - hora: ${this.state.horacortel} - imgT: ${this.state.tablaFlag}`
            );*/
          }
        );
      } else {
        ToastsStore.warning(
          "La fecha final de la tabla no debe sobrepasar por 7 días la inicial o ser menos a la inicial."
        );
      }
    } else {
      this.setState({ fechat2: "", fechat2mod: "" }, () => {
        ToastsStore.warning(
          "Introduzca primero la fecha inicial y vuelva a seleccionar la fecha final."
        );
      });
      //alerta de poner primero fechat1, buscar el Toast!!
    }
  }

  //observaciones por hoja
  onChangeObservaciones = (e) => {
    this.setState({ observaciones: e.target.value });
  };
  //observaciones por hoja
  onChangeObservaciones2 = (e) => {
    this.setState({ observaciones2: e.target.value });
  };
  //observaciones por hoja
  onChangeObservaciones3 = (e) => {
    this.setState({ observaciones3: e.target.value });
  };
  //observaciones por hoja
  onChangeObservaciones4 = (e) => {
    this.setState({ observaciones4: e.target.value });
  };
  //Funciones de obtener sitios
  obtenerSitiosElegidos = async (SitiosE) => {
    //buscar disfernecias con la tabla anterior
    let fss = false;
    const n = SitiosE.length;
    if (n >= this.state.tabla.length && n > 0) {
      if (n > 11) {
        //Alerta de Mayor a 11
        ToastsStore.warning(
          "Has Alcanzado el Límite de 11 Sitios, si agregas más no se verán reflejados en las gráficas."
        );
      } else {
        //aumentó el número de sitios
        //buscar el nuevo id añadido y agregarlo a la lista de peticion del backend
        if (n > 5) {
          fss = true;
        }
        const add = SitiosE[n - 1];
        this.setState({
          hoja12: fss,
          pedirgraf: false,
          pedirmapa: false,
          tabla: SitiosE,
          tablaFlag: true,
          peticion: [...this.state.peticion, add]
        });
      }
    } else {
      const n2 = this.state.datos.length;
      if (n !== 0 && n2 !== 0) {
        //diminuyó el número de sitios de la tabla
        //buscar el id eliminado
        if (n2 > 5) {
          fss = true;
        } else {
          fss = false;
        }
        this.setState({ crear: false, hoja12: fss }, () => {
          var pos;
          for (let j = 0; j < this.state.tabla.length; j++) {
            let esta = false;
            for (let k = 0; k < SitiosE.length; k++) {
              if (this.state.tabla[j].sitio === SitiosE[k].sitio) {
                esta = true;
              }
            }
            if (esta === false) {
              pos = this.state.tabla[j];
            }
          }

          const checkRedux = ({ sitio }) => {
            return sitio !== pos.sitio;
          };
          //sacarlo de la tabla
          const redux = this.state.tabla.filter(checkRedux);
          const redux2 = this.state.datos.filter(checkRedux);
          const redux3 = this.state.peticion.filter(checkRedux);
          this.setState(
            {
              pedirgraf: false,
              pedirmapa: false,
              tabla: redux,
              datos: redux2,
              peticion: redux3
            },
            () => {
              this.setState({ crear: true });
            }
          );
        });
      } else {
        this.setState({
          tabla: [],
          datos: [],
          peticion: [],
          crear: false,
          hoja12: false,
          tablaFlag: false
        });
      }
    }
  };

  //Funciones de tablas e imagenes sn SVG
  obtenerGrafp = async (img, nombreimg, t1, t2) => {
    //buscar si esta imagen esta en el arreglo de img
    const checkRedux = ({ nombre }) => {
      return nombre === nombreimg;
    };
    const r = this.state.allgrafIMG1.filter(checkRedux);

    //sino está guardarlo como arreglo
    if (r.length === 0) {
      this.setState(
        {
          allgrafIMG1: [
            ...this.state.allgrafIMG1,
            { nombre: nombreimg, img: img, t1: t1, t2: t2 }
          ]
        },
        () => {
          if (this.state.allgrafIMG1.length === this.state.datos.length) {
            this.setState({ imgGrafF: true, pedirgraf: false }, () => {
              console.log(this.state.allgrafIMG1);
            });
          }
        }
      );
    }
  };

  obtenerGraf = async (num, img, nombreimg, t1, t2) => {
    //sino está guardarlo como arreglo
    if (num === 1) {
      this.setState(
        {
          h1g1: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          this.setState({ crear: true, spin2: false });
        }
      );
    }
    if (num === 2) {
      this.setState(
        {
          h1g2: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //console.log(this.state.h1g2);
        }
      );
    }
    if (num === 3) {
      this.setState(
        {
          h1g3: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //console.log(this.state.h1g3);
        }
      );
    }
    if (num === 4) {
      this.setState(
        {
          h1g4: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          // console.log(this.state.h1g4);
        }
      );
    }
    if (num === 5) {
      this.setState(
        {
          h1g5: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          // console.log(this.state.h1g5);
        }
      );
    }
    if (num === 6) {
      this.setState(
        {
          h1g6: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //console.log(this.state.h1g6);
        }
      );
    }
    if (num === 7) {
      //console.log("entro7");
      this.setState(
        {
          h1g7: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //console.log(this.state.h1g7);
        }
      );
    }
    if (num === 8) {
      //console.log("entro8");
      this.setState({
        h1g8: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 9) {
      // console.log("entro9");
      this.setState({
        h1g9: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 10) {
      // console.log("entro10");
      this.setState({
        h1g10: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 11) {
      // console.log("entro11");
      this.setState({
        h1g11: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
  };

  obtenerGraf2 = async (num, img, nombreimg, t1, t2) => {
    //sino está guardarlo como arreglo
    if (num === 1) {
      this.setState(
        {
          h2g1: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //console.log(this.state.h2g1);
        }
      );
    }
    if (num === 2) {
      this.setState(
        {
          h2g2: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          // console.log(this.state.h2g2);
        }
      );
    }
    if (num === 3) {
      this.setState(
        {
          h2g3: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          // console.log(this.state.h2g3);
        }
      );
    }
    if (num === 4) {
      this.setState(
        {
          h2g4: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //  console.log(this.state.h2g4);
        }
      );
    }
    if (num === 5) {
      this.setState(
        {
          h2g5: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          //  console.log(this.state.h2g5);
        }
      );
    }
    if (num === 6) {
      this.setState(
        {
          h2g6: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
          imgGrafF: true
        },
        () => {
          // console.log(this.state.h2g6);
        }
      );
    }
    if (num === 7) {
      this.setState({
        h2g7: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 8) {
      this.setState({
        h2g8: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 9) {
      this.setState({
        h2g9: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 10) {
      this.setState({
        h2g10: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
    if (num === 11) {
      this.setState({
        h2g11: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }],
        imgGrafF: true
      });
    }
  };

  obtenerGraf2p = async (img, nombreimg, t1, t2) => {
    //buscar si esta imagen esta en el arreglo de img
    const checkRedux = ({ nombre }) => {
      return nombre === nombreimg;
    };
    const r = this.state.allgrafIMG2.filter(checkRedux);

    //sino está guardarlo como arreglo
    if (r.length === 0) {
      this.setState(
        {
          allgrafIMG2: [
            ...this.state.allgrafIMG2,
            { nombre: nombreimg, img: img, t1: t1, t2: t2 }
          ]
        },
        () => {
          // console.log(this.state.allgrafIMG2);
          if (this.state.allgrafIMG2.length === this.state.datos2.length) {
            this.setState({ imgGrafF2: true, pedirgraf2: false }, () => {
              //console.log(this.state.allgrafIMG2);
            });
          }
        }
      );
    }
  };

  obtenerGrafh1 = async (img, nombreimg, t1, t2) => {
    this.setState(
      {
        allgrafIMGh1: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }]
      },
      () => {
        this.setState({ imgGrafFh1: true, pedirgrafh1: false }, () => {
          console.log(this.state.allgrafIMGh1);
        });
      }
    );
  };

  obtenerGrafh2 = async (img, nombreimg, t1, t2) => {
    this.setState(
      {
        allgrafIMGh2: [{ nombre: nombreimg, img: img, t1: t1, t2: t2 }]
      },
      () => {
        this.setState({ imgGrafFh2: true, pedirgrafh2: false }, () => {
          // console.log(this.state.allgrafIMGh2);
        });
      }
    );
  };

  obtenerGrafT = async (img, nombreimg, t1) => {
    this.setState(
      {
        allgrafIMGt: [{ nombre: nombreimg, img: img, t1: t1 }]
      },
      () => {
        //  console.log(this.state.allgrafIMGt);
        this.setState({ imgGrafFT: true, pedirgraft: false });
      }
    );
  };

  obtenerB1 = async (img, nombreimg) => {
    this.setState(
      {
        barra1IMG: [{ nombre: nombreimg, img: img }]
      },
      () => {
        this.setState({ barra1IMGF: true, pedirgraft: false }, () => {
          //console.log(this.state.barra1IMG);
        });
      }
    );
  };

  obtenerB2 = async (img, nombreimg) => {
    this.setState(
      {
        barra2IMG: [{ nombre: nombreimg, img: img }]
      },
      () => {
        this.setState({ barra2IMGF: true, pedirgraft: false }, () => {
          // console.log(this.state.barra2IMG);
        });
      }
    );
  };

  obtenerB3 = async (img, nombreimg) => {
    this.setState(
      {
        barra3IMG: [{ nombre: nombreimg, img: img }]
      },
      () => {
        this.setState({ barra3IMGF: true, pedirgraft: false }, () => {
          // console.log(this.state.barra3IMG);
        });
      }
    );
  };

  obtenerB4 = async (img, nombreimg) => {
    this.setState(
      {
        barra4IMG: [{ nombre: nombreimg, img: img }]
      },
      () => {
        this.setState({ barra4IMGF: true, pedirgraft: false }, () => {
          // console.log(this.state.barra4IMG);
        });
      }
    );
  };

  obtenerListaTabla = (lista) => {
    this.setState({ tablanueva: lista });
  };

  prepararPDF = async () => {
    this.setState({
      spin2: true,
      pedirgraf: true,
      pedirgraf2: true,
      pedirgrafh1: true,
      pedirgraft: true,
      acumulador: this.state.acumulador + 1
    });
    //console.log("preparar PDF");
    // this.obtenerTabla(this.state.tablanueva);
    //this.obtenerTabla2(this.state.tablanueva2);
  };
  descargarPDF = async () => {
    //console.log("descargarPDF");
    this.setState({ hacerPDF: true });
  };

  render() {
    const f1 = this.state.fecha1l;
    const f2 = this.state.fecha2l;
    const ft2 = this.state.fechat2l;
    const hora = this.state.horacortel;
    let numero = 0;
    const crear = this.state.crear;
    //const imgG = this.state.imgGrafF;
    const imgM = true;
    const imgT = this.state.tablaFlag;
    const spin1 = this.state.spin1;
    const hacerPDF = this.state.hacerPDF;
    const spin2 = this.state.spin2;

    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="lecturas" />
          <section className="widget-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12 page-title">
                  <h2>Producción de Agua</h2>
                </div>
              </div>
              <div className="content-area">
                <div className="row bordes">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h3 className="h3">
                      Ingresa los datos requeridos para obtener el reporte de
                      Producción de Agua
                    </h3>
                    <form>
                      <div className="row">
                        <div className="col-sm-6 col-md-6 col-xl-4    mb-3 input-group">
                          <div className="input-group-prepend ">
                            <label
                              className="input-group-text cuadritos"
                              htmlFor="fecha1"
                            >
                              Fecha Inicial Gráfica
                            </label>
                          </div>
                          <input
                            id="fecha1"
                            onChange={this.fecha1.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Inicial"
                            aria-label="Fecha Inicial"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className=" col-sm-6 col-md-6 col-xl-4 mb-3 input-group">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text cuadritos"
                              htmlFor="fecha2"
                            >
                              Fecha Final Gráfica
                            </label>
                          </div>
                          <input
                            id="fecha2"
                            onChange={this.fecha2.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Final"
                            aria-label="Fecha fianl"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>

                        <div className=" col-sm-6 col-md-6 col-xl-4 mb-3 input-group">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text cuadritos2"
                              htmlFor="hora"
                            >
                              Hora de Corte
                            </label>
                          </div>

                          <select
                            id="hora"
                            onChange={this.horacorte.bind(this)}
                            className="form-control"
                          >
                            <option val="-">Seleccione la hora de corte</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                          </select>
                        </div>

                        <div className=" col-sm-6 col-md-6 col-lg-6 col-xl-4  mb-3 input-group">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Elegir Los Sitios A Generar
                            <span role="img" aria-label="mano">
                              👇
                            </span>
                          </button>
                        </div>

                        {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4  mb-3 input-group">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text cuadritos"
                              htmlFor="titulore"
                            >
                              Título Reporte
                            </label>
                          </div>
                          <input
                            className=" form-control"
                            type="text"
                            id="titulore"
                            placeholder="Ingrese el título para el reporte"
                            value={this.state.tituloreporte}
                            onChange={this.tituloreportec}
                          ></input>
                        </div>

                        <div className="col-md-3  mb-3 input-group">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text cuadritos"
                              htmlFor="titulo"
                            >
                              Título Hoja 1
                            </label>
                          </div>
                          <input
                            className=" form-control"
                            type="text"
                            placeholder="Ingrese el título para hoja 1"
                            value={this.state.titulorojo}
                            onChange={this.titulorojocambia}
                          ></input>
                      </div>*/}
                      </div>

                      <Lista
                        data={this.state.listasitios}
                        parentCallback={this.obtenerSitiosElegidos}
                      />

                      <div className="row text-center">
                        <div className="col-4">
                          <h6 className="h6">
                            Paso 1.- Elija el rango de fechas y sitios deseados
                            para el reporte
                          </h6>
                          {spin1 ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Cargando...</span>
                              </button>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Cargando...
                              </button>
                            </div>
                          ) : f1 && f2 && ft2 && hora && imgT ? (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              onClick={this.cargarlecturas}
                            >
                              Cargar Lecturas
                            </button>
                          ) : (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              aria-disabled="true"
                              disabled
                            >
                              Cargar Lecturas
                            </button>
                          )}
                        </div>
                        <div className="col-4">
                          <h6 className="h6">
                            Paso 2.- Si está listo para descargar el reporte de
                            clic aquí
                          </h6>
                          {spin2 ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Cargando...</span>
                              </button>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Cargando...
                              </button>
                            </div>
                          ) : crear ? (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              onClick={this.prepararPDF}
                            >
                              Preparar Reporte
                            </button>
                          ) : (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              aria-disabled="true"
                              disabled
                            >
                              Preparar Reporte
                            </button>
                          )}
                        </div>
                        <div className="col-4">
                          <h6 className="h6">Paso 3.- Descargar Reporte</h6>
                          {crear && imgM ? (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              onClick={this.descargarPDF}
                            >
                              Descargar Reporte
                            </button>
                          ) : (
                            <button
                              className="btn  btn-primary"
                              type="button"
                              aria-disabled="true"
                              disabled
                            >
                              Reporte Listo - Descargar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <hr></hr>
                <div id="reporteb1" className="row bordes">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h3 className="h3">
                      Sistema Integrado de Monitoreo y Manejo de Agua Sac-tun
                      <br />
                      {this.state.tituloreporte} Reporte de producción de agua{" "}
                      <span>{this.state.fecha1mod}</span> a{" "}
                      <span>{this.state.fecha2mod}</span>
                      <br />
                      <span style={{ color: "red" }}>
                        {this.state.titulorojo}
                      </span>
                    </h3>
                  </div>
                  {this.state.datos.map((dato) => {
                    numero += 1;
                    return (
                      <GL
                        num={numero}
                        key={dato.Sitio}
                        lecturas={dato}
                        hora={this.state.horacorte}
                        pedir={this.state.pedirgraf}
                        acumulador={this.state.acumulador}
                        parentCallback={this.obtenerGraf}
                        pa={this.activarBotonAct}
                      />
                    );
                  })}
                  {/*crear && !spin2 && (
                    <MapaV
                      sitios={this.state.datos}
                      parentCallback1={this.obtenerZoom}
                      parentCallback2={this.obtenerCentro}
                    />
                  )*/}

                  {/*crear && spin2 && (
                    <Mapa
                      pedir={this.state.pedirmapa}
                      sitios={this.state.datos}
                      centro={this.state.centermap}
                      zoo={this.state.zoom}
                      parentCallback={this.obtenerMapa}
                    />
                  )*/}
                </div>
                <hr></hr>
                <div id="reporteb3" className="row bordes">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h3 className="h3">
                      Sistema Integrado de Monitoreo y Manejo de Agua Sactun
                      <br />
                      {this.state.tituloreporte} Reporte de producción de agua{" "}
                      <span>{this.state.fecha1mod}</span> a{" "}
                      <span>{this.state.fecha2mod}</span>
                      <br />
                      <span style={{ color: "red" }}>
                        {this.state.titulorojo3}
                      </span>
                    </h3>
                  </div>

                  {crear && (
                    <GT
                      titulo={this.state.titulog3h3}
                      fecha1={this.state.fecha1mod}
                      fecha2={this.state.fecha2mod}
                      lecturas={this.state.datos3[0].Completo[0]}
                      hoja1={this.state.datos}
                      hoja2={this.state.datos2}
                      pedir={this.state.pedirgraft}
                      acumulador={this.state.acumulador}
                      parentCallback={this.obtenerGrafT}
                    />
                  )}
                </div>
                <hr></hr>

                {/*Creación del PDF */}
                {crear && hacerPDF && (
                  <div className="row">
                    <h3 className="h3">Reporte PDF Generado</h3>
                    <hr />
                    <div className="col-12 bordes" style={{ height: "1000px" }}>
                      <PDFViewer width="100%" height="100%">
                        <PDFReporteB
                          fondo={Panel}
                          datos={this.state.datos}
                          fecha1={this.state.fecha1mod}
                          fecha2={this.state.fecha2mod}
                          comentarios1={this.state.observaciones}
                          g1={this.state.h1g1}
                          g2={this.state.h1g2}
                          g3={this.state.h1g3}
                          g4={this.state.h1g4}
                          g5={this.state.h1g5}
                          g6={this.state.h1g6}
                          g7={this.state.h1g7}
                          g8={this.state.h1g8}
                          g9={this.state.h1g9}
                          g10={this.state.h1g10}
                          g11={this.state.h1g11}
                          gt={this.state.allgrafIMGt}
                          gtd={this.state.datos3[0].Sitios1[0]}
                          tituloreporte={this.state.tituloreporte}
                        />
                      </PDFViewer>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                <ToastsContainer store={ToastsStore} />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Lecturas);
