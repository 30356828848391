import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
//const BORDER_COLOR = "#007bff";
//const BORDER_STYLE = "solid";
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff"
  },
  section: {
    margin: 0,
    flexGrow: 1
  },
  panel: {
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    height: "100%"
  },
  table: {
    width: "100%",
    padding: "10px",
    display: "table"
  },
  tableRow: {
    margin: "auto",
    alignContent: "center",
    flexDirection: "row"
  },
  tt: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    marginLeft: 20
  },
  ttv: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    marginLeft: 10
  },
  portada1: {
    textTransform: "uppercase",
    top: 115,
    position: "absolute",
    alignSelf: "center",
    width: "90%",
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    color: "#1A2A49"
  },
  subt: {
    textTransform: "uppercase",
    marginTop: 100,
    width: "100%",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    color: "#1A2A49"
  },
  gasto: {
    textTransform: "uppercase",
    marginTop: 260,
    fontSize: 35,
    textAlign: "center",
    color: "#F4E97F"
  },
  volumen: {
    textTransform: "uppercase",
    marginTop: 260,
    fontSize: 35,
    textAlign: "center",
    color: "#F4E97F"
  },
  grafica: {
    marginTop: 30,
    width: "100%"
  }
});

const PDF_B = ({
  fondo,
  datos,
  fecha1,
  fecha2,
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  g10,
  g11,
  gt,
  gtd
}) => {
  const graf = [g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11];
  const largo = datos.length;
  return (
    <Document>
      {datos.map((x, index) => {
        return (
          <Page
            size="LETTER"
            title={`Hoja ${index}`}
            style={styles.page}
            wrap={true}
            key={`Hoja ${index}`}
          >
            <View style={styles.section} wrap>
              <Image style={styles.panel} src={fondo} />
              <Text
                style={styles.portada1}
                render={() =>
                  `Producción de agua del sitio ${x.dato[0].nombre} de ${fecha1} a ${fecha2}`
                }
              ></Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tt}>
                    <Text
                      style={styles.gasto}
                      render={() => `${x.tb[0].cp.toFixed(3)}`}
                    ></Text>
                  </View>
                  <View style={styles.ttv}>
                    <Text
                      style={styles.volumen}
                      render={() => `${x.tb[0].vt.toFixed(3)}`}
                    ></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <Text
                    style={styles.subt}
                    render={() =>
                      `Volumen Diario Extraído de ${fecha1} a ${fecha2}`
                    }
                  ></Text>
                </View>
                <View style={styles.tableRow}>
                  <Image style={styles.grafica} src={graf[index][0].img} />
                </View>
              </View>
            </View>
          </Page>
        );
      })}

      {largo > 1 && (
        <Page
          size="LETTER"
          title={`Hoja resumen`}
          style={styles.page}
          wrap={true}
        >
          <View style={styles.section} wrap>
            <Image style={styles.panel} src={fondo} />
            <Text
              style={styles.portada1}
              render={() => `Producción Total de ${fecha1} a ${fecha2}`}
            ></Text>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tt}>
                  <Text
                    style={styles.gasto}
                    render={() => `${gtd.tb[0].cp.toFixed(3)}`}
                  ></Text>
                </View>
                <View style={styles.ttv}>
                  <Text
                    style={styles.volumen}
                    render={() => `${gtd.tb[0].vt.toFixed(3)}`}
                  ></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <Text
                  style={styles.subt}
                  render={() =>
                    `Volumen Diario Extraído de ${fecha1} a ${fecha2}`
                  }
                ></Text>
              </View>
              <View style={styles.tableRow}>
                <Image style={styles.grafica} src={gt[0].img} />
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

const MyDocument = ({
  fondo,
  datos,
  fecha1,
  fecha2,
  comentarios1,
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  g10,
  g11,
  gt,
  gtd
}) => {
  return (
    <PDF_B
      fondo={fondo}
      datos={datos}
      fecha1={fecha1}
      fecha2={fecha2}
      comentarios1={comentarios1}
      g1={g1}
      g2={g2}
      g3={g3}
      g4={g4}
      g5={g5}
      g6={g6}
      g7={g7}
      g8={g8}
      g9={g9}
      g10={g10}
      g11={g11}
      gt={gt}
      gtd={gtd}
    />
  );
};

export default MyDocument;
