import React, { Component } from "react";
import logo from "../v2/images/sactunlogo.png";
export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activo: this.props.activo
    };
  }

  render() {
    return (
      <header>
        <div className="container">
          <div className="row header-cnt">
            <div className="col-md-4 col-sm-4 col-xs-4">
              <a className="mburger mburger--collapse" href="#my-menu">
                <b></b>
                <b></b>
                <b></b>
              </a>
              <nav id="my-menu" className="navbar-expand-sm">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <a href={`/mod/modreporteg`} className="menu-item">
                      Reporte
                    </a>
                  </li>

                  <li className="nav-item ">
                    <a href={`/mapa`} className="menu-item">
                      Mapa
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-4">
              <div className="logo-img" title="Sactun Logo">
                <a href={`/mapa`}>
                  <img src={logo} alt="Logo" />
                </a>
              </div>
            </div>

            <div className="row menu">
              <nav className="navbar-expand-sm">
                <ul className="navbar-nav">
                  {
                    <li className="nav-item active">
                      {" "}
                      <a href={`/mod/modreporteg`}>Reporte</a>
                    </li>
                  }

                  <li className="nav-item">
                    {" "}
                    <a href={`/mapa`}>Mapa</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
