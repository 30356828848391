import React, { Fragment, useState, useEffect, useCallback } from "react";
import Canvg, { presets } from "canvg";
const Tabla7 = ({ lecturas, parentCallback }) => {
  const [lecturass, setlecturas] = useState([]);
  const [url, seturl] = useState("");
  const [flag_bot, setflag_bot] = useState(false);
  const enviartabla = useCallback(() => {
    if (url !== "") {
      parentCallback(url);
      setflag_bot(true);
    }
    // eslint-disable-next-line
  }, [url]);
  useEffect(() => {
    enviartabla();
  }, [url, enviartabla]);
  useEffect(() => {
    setlecturas(lecturas);
  }, [lecturas]);

  useEffect(() => {
    if (lecturass.length > 0) {
      obtenerTabla(lecturass);
    }
  }, [lecturass]);

  const obtenerTabla = async (tabla) => {
    const alto = 60;
    const num = tabla.length;
    let renglones =
      "<svg width='950px' height='" +
      (alto + num * 30) +
      "px' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
      '<rect x="0" y="0" width="950" height="60" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="730" height="60" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="470" height="60" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="210" height="60" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <text x="20" y="20" font-family="Verdana" font-size="16px" font-weight="bold" fill="black" text-anchor="middle"> <tspan x="100">Fecha</tspan> <tspan x="340">Caudal Promedio</tspan> <tspan x="600">Volumen Diario</tspan> <tspan x="837">Volumen Acumulado</tspan>  </text> <text x="30" y="50" font-family="Verdana" font-size="15px" text-anchor="middle"> <tspan x="30"></tspan> <tspan x="340">(l/s)</tspan> <tspan x="605">(m3)</tspan> <tspan x="830">(m3)</tspan></text>';
    let acum = 60;
    for (let index = 0; index < tabla.length; index++) {
      let f = tabla[index].fechalectura.split("T")[0].split("-");

      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="950" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';

      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="730" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="470" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';

      renglones =
        renglones +
        '<text x="0" y="' +
        (acum + 20) +
        '" font-family="Verdana" font-size="14px" text-anchor="start"> <tspan x="55" font-weight="bold">' +
        `${f[2]}-${f[1]}-${f[0]}` +
        '	</tspan><tspan x="310" font-weight="bold">' +
        `${Number(tabla[index].caudal).toFixed(2)}` +
        '</tspan><tspan x="570" font-weight="bold">' +
        `${Number(tabla[index].volumen).toFixed(2)}` +
        '</tspan><tspan x="800" font-weight="bold">' +
        `${Number(tabla[index].vola).toFixed(2)}` +
        "</tspan></text>";
      acum += 30;
    }
    renglones = renglones + "</svg>";
    const width = 950;
    const height = alto + num * 30;
    const svg = renglones;
    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svg, presets.offscreen());

    // Render only first frame, ignoring animations and mouse.
    await v.render();

    const blob = await canvas.convertToBlob();
    const pngUrl = URL.createObjectURL(blob);
    seturl(pngUrl);
  };

  return (
    <Fragment>
      {flag_bot && (
        <div className="row mb-1  text-right">
          <div className="col-12 ">
            <div className="btn-group" role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a
                  rel="noreferrer"
                  className="btn btn-link"
                  href={url}
                  download
                >
                  Descargar PNG
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <table className="table table-sm table-bordered">
        {lecturass.length > 0 && (
          <thead>
            <tr>
              <th className="bg-info" scope="col">
                Fecha
              </th>
              <th className="bg-info" scope="col">
                Gasto Promedio (l/s)
              </th>
              <th className="bg-info" scope="col">
                Volumen Diario (m3)
              </th>
              <th className="bg-info" scope="col">
                Volumen Acumulado (m3)
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {lecturass.map((lec) => {
            const f = lec.fechalectura.split("T")[0].split("-");

            return (
              <tr key={lec.fechalectura}>
                <th scope="row">{`${f[2]}-${f[1]}-${f[0]}`}</th>
                <td>{Number(lec.caudal).toFixed(2)}</td>
                <td>{Number(lec.volumen).toFixed(2)}</td>
                <td>{Number(lec.vola).toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Tabla7;
