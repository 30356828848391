import React, { Component, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import Canvg, { presets } from "canvg";
import T1 from "./Tabla7.js";
import T2 from "./Tabla2.js";
HighchartsExporting(Highcharts);

export class GCota extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);
    this.state = {
      chartOptions: {
        chart: { zoomType: "xy" },
        credits: { enabled: !1 },
        title: {
          text: ""
        },
        subtitle: { text: "Seleccione una sección para realizar zoom" },
        legend: {
          // layout: 'horizontal', // default
          itemDistance: 50,
          itemStyle: {
            fontSize: "15px"
          }
        },

        xAxis: [
          {
            labels: {
              overflow: "allow",
              style: {
                fontSize: "15px"
              }
            }
          }
        ],
        yAxis: [
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Volumen",
              margin: 50,
              style: {
                fontSize: "25px",
                color: "darkblue"
              }
            },
            labels: {
              format: "{value} m3",
              style: {
                color: "darkblue",
                fontSize: "20px"
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          xDateFormat: "%d-%m-%Y",
          style: {
            fontSize: "15px"
          }
        },
        plotOptions: {
          spline: {
            lineWidth: 3.5,
            states: { hover: { lineWidth: 4 } },
            marker: { enabled: !1 },
            dataLabels: {
              enabled: false,
              style: {
                fontSize: "15px"
              }
            }
          },
          column: {
            stacking: "normal",
            dataLabels: {
              format: "{point.y:.1f}",
              enabled: true,
              align: "center",
              verticalAlign: "middle",
              rotation: 270,
              y: -22,
              style: {
                fontWeight: "light",
                fontSize: "19px",
                color: "white"
              }
            }
          }
        },
        series: [],
        navigation: { menuItemStyle: { fontSize: "15px" } },
        exporting: {
          filename:
            `Producción de Agua ` +
            this.props.lecturas.dato[0].nombre +
            "-" +
            this.props.lecturas.Sitio.split("-")[1],
          sourceWidth: 1920,
          sourceHeight: 720,
          chartOptions: { subtitle: null }
        }
      },
      contador: -10,
      c: null,
      t1: "",
      t2: "",
      png: "",
      show: false
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;

    const lecturas = this.props.lecturas;
    if (this.state.contador !== this.props.acumulador) {
      this.setState({ contador: this.props.acumulador }, () => {
        const fech = lecturas.lecturas.map(function (lec) {
          var fd = lec.fechalectura.split("T");
          var di = fd[0].split("-");
          var di2 = fd[1].split(":");
          var seg = di2[2].split(".");
          return new Date(di[0], di[1] - 1, di[2], di2[0], di2[1], seg[0])
            .toLocaleString("MX")
            .split(" ")[0];
        });

        /* const r = lecturas.lecturas.map(function (lec) {
          return Number(Number(lec.caudal).toFixed(2));
        });*/
        const r2 = lecturas.lecturas.map(function (lec) {
          return Number(Number(lec.volumen).toFixed(2));
        });
        /* const r3 = lecturas.lecturas.map(function (lec) {
          return Number(Number(lec.vola).toFixed(2));
        });*/
        this.setState({
          chartOptions: {
            xAxis: {
              categories: fech
            },
            series: [
              {
                name: "Volumen Diario m3",
                type: "column",
                color: "Navy",
                data: r2,
                yAxis: 0,
                tooltip: {
                  valueSuffix: " m3"
                }
              }
            ]
          }
        });

        this.internalChart.reflow();
        this.setState({ c: this.internalChart });
      });
    }
  }
  recibirAck = (val) => {
    // console.log(val);
    this.props.pa(val);
  };
  async componentDidUpdate() {
    const pedir = this.props.pedir;

    if (this.state.contador !== this.props.acumulador) {
      this.setState({ contador: this.props.acumulador }, () => {
        if (pedir === true) {
          this.hacerIMG();
        }
      });
    }
  }
  recibirt1 = (tabla) => {
    this.setState({ t1: tabla });
  };
  recibirt2 = (tabla) => {
    this.setState({ t2: tabla });
  };
  async hacerIMG() {
    let svgG = this.state.c;
    const width = 1920;
    const height = 720;
    const svg = svgG.getSVGForExport();
    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svg, presets.offscreen());

    // Render only first frame, ignoring animations and mouse.
    await v.render();

    const blob = await canvas.convertToBlob();
    const pngUrl = URL.createObjectURL(blob);

    this.setState({ png: pngUrl }, () => {
      const n = this.props.lecturas.Sitio.split("-");
      this.mandarD(
        this.props.num,
        pngUrl,
        this.props.lecturas.dato[0].nombre + (n[2] !== "1" ? "-" + n[1] : ""),
        this.state.t1,
        this.state.t2
      );
    });
  }

  mandarD(d0, d1, d2, d3, d4) {
    this.props.parentCallback(d0, d1, d2, d3, d4);
  }

  async componentDidMount() {
    const tip = JSON.parse(localStorage.getItem("tipo"));
    if (tip.tipo === "admin") {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }

  render() {
    const n = this.props.lecturas.Sitio.split("-");
    //console.log(this.props.hora);
    return (
      <Fragment>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 text-center columna overflow-auto">
          <div className="content-stats">
            <div className="title-sec">
              <h3>
                Producción de Agua
                {this.props.lecturas.dato[0].nombre &&
                  " " +
                    this.props.lecturas.dato[0].nombre +
                    (n[2] !== "1" ? "-" + n[1] : "")}
              </h3>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={this.state.chartOptions}
              callback={this.afterChartCreated}
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 text-center columna overflow-auto">
          <div className="content-stats">
            <div className="title-sec">
              <T1
                lecturas={this.props.lecturas.ta}
                parentCallback={this.recibirt1}
              />
              <T2
                lecturas={this.props.lecturas.tb}
                parentCallback={this.recibirt2}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GCota;
