import React, { useCallback, useEffect, useState } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
const ListaSitiosZ1 = ({ parentCallback, data }) => {
  const [Sitios, setSitios] = useState([]);
  const [SitiosSelec, setSitiosSelec] = useState([]);
  useEffect(() => {
    setSitios(data);
  }, [data]);

  const selSitio = (e) => {
    if (e.target.checked) {
      if (SitiosSelec.length < 11) {
        setSitiosSelec([...SitiosSelec, { sitio: e.target.value }]);
      } else {
        ToastsStore.warning(
          "Has Alcanzado el Límite de 5 Sitios, si agregas más no se verán reflejados en las gráficas."
        );
      }
    } else {
      const checkRedux = ({ sitio }) => {
        return sitio !== e.target.value;
      };
      const redux = SitiosSelec.filter(checkRedux);
      setSitiosSelec(redux);
    }
  };

  const pasarTabla = useCallback(() => {
    parentCallback(SitiosSelec);
  }, [SitiosSelec, parentCallback]);

  useEffect(() => {
    pasarTabla();
  }, [SitiosSelec, pasarTabla]);
  const allcheck1 = Sitios.map((sitio) => {
    return (
      <div
        key={`l1-${sitio.idSitio}-${sitio.canal}`}
        className="col-xl-2 col-md-2 col-lg-2 col-sm-6 col-6 mb-3 text-left "
      >
        <div className="custom-control custom-checkbox ">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`l1-${sitio.idSitio}-${sitio.canal}`}
            name={`l1-${sitio.idSitio}-${sitio.canal}`}
            value={`${sitio.idSitio}-${sitio.canal}-${sitio.tipo}`}
            onChange={(e) => selSitio(e)}
          />
          <label
            className="custom-control-label"
            htmlFor={`l1-${sitio.idSitio}-${sitio.canal}`}
          >
            <b>
              {sitio.tipo === 2 || sitio.tipo === 8
                ? `${sitio.nombre} - ${sitio.canal}`
                : sitio.nombre}
            </b>
          </label>
        </div>
      </div>
    );
  });

  return (
    <div className="row collapse" id="collapseExample">
      <div className="col-12 mb-3">
        <h3 className="h3">Elige los sitios que requieres para tu reporte</h3>
        <ToastsContainer store={ToastsStore} />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <div className="row">{allcheck1}</div>
      </div>
    </div>
  );
};

export default ListaSitiosZ1;
