import React, { Component } from "react";
import logo from "../images/sactunlogo.png";

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activo: this.props.activo
    };
  }

  render() {
    var query = window.location.href;
    var sit = query.split("/");
    const { activo } = this.state;
    return (
      <header>
        <div className="container">
          <div className="row header-cnt">
            <div className="col-md-4 col-sm-4 col-xs-4">
              <a className="mburger mburger--collapse" href="#my-menu">
                <b></b>
                <b></b>
                <b></b>
              </a>
              <nav id="my-menu" className="navbar-expand-sm">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <a href={`/dashboard/1/${sit[5]}`} className="menu-item">
                      Vista General
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href={`/graficas/1/${sit[5]}`} className="menu-item">
                      Gráficas
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href={`/lecturas/1/${sit[5]}`} className="menu-item">
                      Lecturas
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href={`/volumen/1/${sit[5]}`} className="menu-item">
                      Reportes Volumen
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href={`/informacion/1/${sit[5]}`} className="menu-item">
                      Información del Sitio
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href={`/mapa`} className="menu-item">
                      Mapa
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-4">
              <div className="logo-img" title="Simas Logo">
                <a href={`/mapa`}>
                  <img src={logo} alt="Logo" />
                </a>
              </div>
            </div>

            <div className="row menu">
              <nav className="navbar-expand-sm">
                <ul className="navbar-nav">
                  {activo === "overview" ? (
                    <li className="nav-item active">
                      <a href={`/dashboard/1/${sit[5]}`}>Vista General</a>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <a href={`/dashboard/1/${sit[5]}`}>Vista General</a>
                    </li>
                  )}

                  {activo === "graficas" ? (
                    <li className="nav-item active">
                      {" "}
                      <a href={`/graficas/1/${sit[5]}`}>Gráficas</a>
                    </li>
                  ) : (
                    <li className="nav-item">
                      {" "}
                      <a href={`/graficas/1/${sit[5]}`}>Gráficas</a>
                    </li>
                  )}
                  {activo === "lecturas" ? (
                    <li className="nav-item active">
                      {" "}
                      <a href={`/lecturas/1/${sit[5]}`}>Lecturas</a>
                    </li>
                  ) : (
                    <li className="nav-item">
                      {" "}
                      <a href={`/lecturas/1/${sit[5]}`}>Lecturas </a>
                    </li>
                  )}
                  {activo === "volumen" ? (
                    <li className="nav-item active">
                      {" "}
                      <a href={`/volumen/1/${sit[5]}`}>Reportes Volumen</a>
                    </li>
                  ) : (
                    <li className="nav-item">
                      {" "}
                      <a href={`/volumen/1/${sit[5]}`}>Reportes Volumen </a>
                    </li>
                  )}
                  {activo === "info" ? (
                    <li className="nav-item active">
                      {" "}
                      <a href={`/informacion/1/${sit[5]}`}>
                        Información del Sitio
                      </a>
                    </li>
                  ) : (
                    <li className="nav-item">
                      {" "}
                      <a href={`/informacion/1/${sit[5]}`}>
                        Información del Sitio
                      </a>
                    </li>
                  )}

                  <li className="nav-item">
                    {" "}
                    <a href={`/mapa`}>Mapa</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
