import React, { Fragment, useCallback, useEffect, useState } from "react";
import Canvg, { presets } from "canvg";

const TabalaFinal = ({
  lecturas1,
  lecturas2,
  fecha1,
  fecha2,
  parentCallback
}) => {
  const [vo, setvo] = useState(0);
  const [ca, setca] = useState(0);
  const [f1, setf1] = useState("");
  const [f2, setf2] = useState("");
  const [lecturass, setlecturas] = useState([]);
  const [url, seturl] = useState("");
  const [flag_bot, setflag_bot] = useState(false);
  const enviartabla = useCallback(() => {
    if (url !== "") {
      parentCallback(url);
      setflag_bot(true);
    }
    // eslint-disable-next-line
  }, [url]);
  useEffect(() => {
    enviartabla();
  }, [url, enviartabla]);
  useEffect(() => {
    setlecturas([...lecturas1, ...lecturas2]);
  }, [lecturas1, lecturas2]);
  useEffect(() => {
    setf1(fecha1);
  }, [fecha1]);
  useEffect(() => {
    setf2(fecha2);
  }, [fecha2]);
  useEffect(() => {
    if (lecturass.length > 0) {
      obtenerTabla(lecturass);
    }
  }, [lecturass]);
  const obtenerTabla = async (tabla) => {
    let caudal = 0;
    let volumen = 0;
    let nom = "";
    let n = [];
    const alto = 60;
    const num = tabla.length;
    let renglones =
      "<svg width='570px' height='" +
      (alto + num * 30) +
      "px' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
      '<rect x="0" y="0" width="570" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="400" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <rect x="0" y="0" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/> <text x="30" y="20" font-family="Verdana" font-size="16px" font-weight="bold" fill="black" text-anchor="middle"> <tspan x="105">Pozo</tspan> <tspan x="305">Caudal Medio (lps)</tspan><tspan x="485">Volumen (m3)</tspan>  </text>';
    let acum = 30;
    for (let index = 0; index < tabla.length; index++) {
      n = tabla[index].Sitio.split("-");
      nom = tabla[index].dato[0].nombre + (n[2] !== "1" ? "-" + n[1] : "");
      caudal = caudal + Number(tabla[index].tb[0].cp);
      volumen = volumen + Number(tabla[index].tb[0].vt);
      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="570" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';

      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="400" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
      renglones =
        renglones +
        '<rect x="0" y="' +
        acum +
        '" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
      renglones =
        renglones +
        '<text x="0" y="' +
        (acum + 20) +
        '" font-family="Verdana" font-size="12px" text-anchor="start"> <tspan x="2">' +
        `${nom}` +
        '	</tspan><tspan x="260">' +
        `${Number(tabla[index].tb[0].cp).toFixed(2)}` +
        '</tspan><tspan x="440">' +
        `${Number(tabla[index].tb[0].vt).toFixed(2)}` +
        "</tspan></text>";
      acum += 30;
    }
    renglones =
      renglones +
      '<rect x="0" y="' +
      acum +
      '" width="570" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';

    renglones =
      renglones +
      '<rect x="0" y="' +
      acum +
      '" width="400" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
    renglones =
      renglones +
      '<rect x="0" y="' +
      acum +
      '" width="210" height="30" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,123,255)"/>';
    renglones =
      renglones +
      '<text x="0" y="' +
      (acum + 20) +
      '" font-family="Verdana" font-size="14px" text-anchor="start"> <tspan x="80" font-weight="bold">' +
      `Total` +
      '	</tspan><tspan x="260" font-weight="bold">' +
      `${Number(caudal).toFixed(2)}` +
      '</tspan><tspan x="440" font-weight="bold">' +
      `${Number(volumen).toFixed(2)}` +
      "</tspan></text>";

    renglones = renglones + "</svg>";
    setca(Number(caudal).toFixed(2));
    setvo(Number(volumen).toFixed(2));
    const width = 700;
    const height = alto + num * 30;
    const svg = renglones;
    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svg, presets.offscreen());

    // Render only first frame, ignoring animations and mouse.
    await v.render();

    const blob = await canvas.convertToBlob();
    const pngUrl = URL.createObjectURL(blob);
    seturl(pngUrl);
  };

  return (
    <Fragment>
      <h3 className="h3">
        {" "}
        RESUMEN DE BOMBEO DE POZOS MINA SAC-TUN DEL <span>{f1}</span> a{" "}
        <span>{f2}</span>
      </h3>
      {flag_bot && (
        <div className="row mb-1  text-right">
          <div className="col-12 ">
            <div className="btn-group" role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a
                  rel="noreferrer"
                  className="btn btn-link"
                  href={url}
                  download
                >
                  Descargar PNG
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <table className="table table-sm table-bordered">
        {lecturass.length > 0 && (
          <thead>
            <tr>
              <th className="bg-info" scope="col">
                Pozo
              </th>
              <th className="bg-info" scope="col">
                Gasto Promedio (l/s)
              </th>
              <th className="bg-info" scope="col">
                Volumen (m3)
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {lecturass.map((lec) => {
            const n = lec.Sitio.split("-");
            return (
              <tr key={`${lec.dato[0].nombre}-${Math.random()}`}>
                <td>{`${lec.dato[0].nombre} ${
                  n[2] !== "1" ? "-" + n[1] : ""
                }`}</td>
                <td>{Number(lec.tb[0].cp).toFixed(2)}</td>
                <td>{Number(lec.tb[0].vt).toFixed(2)}</td>
              </tr>
            );
          })}
          <tr key={`suma`}>
            <td className="bg-info">Total</td>
            <td className="bg-warning">{ca}</td>
            <td className="bg-info">{vo}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default TabalaFinal;
